import axios from 'axios';
import { Message } from 'element-ui';
// 创建axios实例
// const url = 'http://192.168.110.89:8888'; //董博
// const url = 'https://prod.bjznyjny.com/'; //生产
const url = 'https://testapi.bjznyjny.com/'; //预生产
// const url = 'http://192.168.110.218:8888'; //春娇
// const url = 'http://devapi.bjznyjny.com/'; //测试
// const url = 'http://192.168.110.98:8888'; //振东
const protocol = location.protocol;
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,

  timeout: 1000 * 30
});
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/json';

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */

instance.interceptors.request.use(
  async (config) => {
    // const token = getApp().globalData.token;

    const token = localStorage.getItem('token');
    if (token) {
      config.headers = {
        ...(config.headers || {}),
        ...JSON.parse(token),
        plantform: 'pc'
        // token: 'qqhY9DuhAaKQrRzJ',
        // user_id: 5,
        // sid: 'abc'
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  async (res) => {
    if (res.headers && res.headers['content-type'] && res.headers['content-type'].indexOf('application/json') == -1) {
      const headers = res.headers;
      const contentType = headers['content-type'];
      const blob = new Blob([res.data], { type: contentType });

      const contentDisposition = res.headers['content-disposition'];
      let fileName = 'stock_record.xlsx';
      if (contentDisposition) {
        fileName = window.decodeURI(res.headers['content-disposition'].split('=')[1]);
      }
      console.log('文件名称：', fileName);

      // 非IE下载
      if ('download' in document.createElement('a')) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob); // 创建下载的链接
        link.download = fileName; // 下载后文件名
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click(); // 点击下载
        window.URL.revokeObjectURL(link.href); // 释放掉blob对象
        document.body.removeChild(link); // 下载完成移除元素
      } else {
        // IE10+下载
        window.navigator.msSaveBlob(blob, fileName);
      }
      return;
    }
    if (res.data && res.data.code == 100001) {
      localStorage.clear();
      this.$router.replace({ path: 'login' });
    }
    if (res.data.code && res.data.code !== 1000) {
      //不等于1000标识错误
      if (res.data.msg) {
        Message({
          message: res.data.msg,
          type: 'error'
        });
      }

      return Promise.reject(res).catch();
    } else {
      if (res.data.data) {
        return res.data.data;
      }
      if (res.data.Content) {
        return res.data;
      }
      return null;
    }
  },
  // 请求失败
  (error) => {
    let msg = '请求失败';
    if (error && error.response && error.response.data && error.response.data.error) {
      msg = error.response.data.error;
    }
    Message({
      message: msg,
      type: 'error'
    });
    return Promise.reject(error.response);
  }
);

export default instance;
